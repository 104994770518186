import CONSTANTS from '@seedcloud/constants'
import { Formik as FormProvider } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Validator from 'yup'

import { InvitePilotForm } from './InvitePilotForm'

import { FormLayoutProvider } from 'components/common/context/FormLayoutContext'
import { FormAction } from 'components/company/common'
import { styled, apply } from 'lib/styled'
import toaster from 'lib/toaster'
import { requiredString, optionalString } from 'utils/validation'

const { string, object, boolean } = Validator

const validationSchema = object({
  avatar: string().optional(),
  firstName: requiredString('First Name'),
  lastName: optionalString('Last Name'),
  phoneNumber: optionalString('Phone number').matches(
    CONSTANTS.REGEX.E164_PHONE_FORMAT,
    'Please include a country code (e.g +64)'
  ),
  email: requiredString('Email').email('Not a valid email address'),
  pilotCategoryId: optionalString('Pilot Category'),
  terms: boolean().oneOf([true], 'You must accept Terms and Condition to continue'),
})

const Container = styled.div(
  apply('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
  })
)

const Scrollable = styled.div(
  apply('flex flex-column p-6', {
    minHeight: 'min-content',
  })
)

function InvitePilot({
  pilotCategoriesList,
  initialValues,
  handleSubmit,
  isInvitingPilot,
  setAvatarFile,
}) {
  const history = useHistory()
  return (
    <Container>
      <FormLayoutProvider value={{ editing: true }}>
        <FormProvider
          id="createPilot"
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values).then(() => {
              toaster.success('Invite pilot success!')
            })
          }}
        >
          {({ resetForm, values, submitForm }) => (
            <>
              <Scrollable>
                <InvitePilotForm
                  setAvatarFile={setAvatarFile}
                  pilotCategoriesList={pilotCategoriesList}
                  values={values}
                />
              </Scrollable>
              <FormAction
                loading={isInvitingPilot}
                loadingText="Inviting"
                okText="Create"
                onCancel={() => {
                  resetForm()
                  history.push('/pilots')
                }}
                onOK={() => {
                  submitForm()
                }}
              />
            </>
          )}
        </FormProvider>
      </FormLayoutProvider>
    </Container>
  )
}

export { InvitePilot }
