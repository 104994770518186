import { pick } from '@seedcloud/ramda-extra'

export const roleKey = 'https://nearbysky.com/roles'

export const ROLES = {
  SUPPLIER_ADMIN: 'supplier_admin',
  PLATFORM_ADMIN: 'platform_admin',
  // Non Admin Roles
  USER: 'user',
  ONBOARDING_PILOT: 'onboarding_pilot',
}

export const ORGANIZATION_ROLES = pick(['ADMIN', 'STAFF'], ROLES)

export const ORGANIZATION_ROLE_DISPLAY_NAMES = {
  [ORGANIZATION_ROLES.ADMIN]: 'Organization Admin',
  [ORGANIZATION_ROLES.STAFF]: 'Staff',
}

export const ENTITY_TYPE = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
}

export const FLEETCODE_NETWORK_TYPES = {
  NBS: 'NBS',
}

export const INVITATION_STATUS = {
  INACTIVE: 'invited',
  ACTIVE: 'active',
}
